import React, { useEffect } from "react";
import "../../styles/About.css";
import MainReturnButton from "../../UI/MainReturnButton";
import LeftMenu from "../../UI/LeftMenu";
import { ABOUT_ROUTES } from "../../utils/consts";
import { titleName } from "../../store/routes";
const About = () => {
  useEffect(() => {
    document.title = "О заводе" + titleName;
  }, []);
  return (
    <div className="about">
      <h2 className="header-h2">О заводе</h2>
      <MainReturnButton />
      <div className="about-container">
        <LeftMenu arr={ABOUT_ROUTES} />
        <div className="about-content">
          <h2 className="header-h2">Этапы становления завода</h2>
          <div className="about-content-card">
            <span></span>
            <h3>1976</h3>
            <p>
              Постановлением ЦК КПСС и Совета Министров СССР № 609 от 06 августа
              1976 года было принято решение о строительстве Кузнечного завода
              тяжелых штамповок в г. Жодино. Связано это было с острой
              потребностью предприятий автомобильной промышленности в
              крупногабаритных и тяжелых поковках, а также организацией
              производства карьерных самосвалов «БелАЗ» особо большой
              грузоподъемности. Протоколом Минавтопрома СССР № 18 от 15 апреля
              1977 г. был утвержден проект завода. В этом же году
              строительно-монтажный трест № 21 (г. Борисов) приступил к
              строительным работам.
            </p>
            <img src="/images/about-images/1976.png" alt="1976" />
          </div>
          <div className="about-content-card">
            <h3>1982</h3>
            <p>
              Первым директором строящегося завода был назначен Матусевич Роман
              Николаевич. В конце 1981 года был закончен монтаж оборудования
              фирмы «HOISLER» и «BANNING» (Германия) и введен в эксплуатацию цех
              по производству ободьев колес для карьерных самосвалов «БелАЗ». С
              1982 г. Кузнечный завод тяжелых штамповок приступил к серийному
              выпуску продукции. Последующие годы были периодом постоянного
              динамического развития предприятия: строились современные
              заводские корпуса, вводились новые мощности по производству
              поковок и штамповок массой до 200 кг, осваивались другие виды
              продукции, активно велось строительство жилья и объектов
              социально-бытового назначения.
            </p>
            <img src="/images/about-images/1982.png" alt="1982" />
          </div>
          <div className="about-content-card">
            <h3>1993</h3>
            <p>
              В 1993 году введен в эксплуатацию механизированный комплекс по
              производству лонжеронов на базе четырехкривошипного пресса К4548,
              усилием 63000 кН, что позволило Минскому автомобильному заводу
              существенно увеличить выпуск автомобилей различных модификаций. В
              декабре этого же года началось строительство РБУ, который
              впоследствии стал основой собственной строительной базы, силами
              которой выполняются ремонтно-строительные работы и строится жильё
              для работников завода, производит растворные и бетонные смеси для
              строительных предприятий и частных лиц.
            </p>
            <img src="/images/about-images/1993.png" alt="1993" />
          </div>
          <div className="about-content-card">
            <h3>2006</h3>
            <p>
              С целью усовершенствования технологии изготовления картера заднего
              моста автомобилей семейства «МАЗ», увеличения их номенклатуры и
              улучшения качества в 2006 году на предприятии внедрена машина
              сварки трением «THOMPSON 125 DE» (Великобритания) и ряд
              специального оборудования по механической обработке.
            </p>
            <img src="/images/about-images/2006.png" alt="2006" />
          </div>
          <div className="about-content-card">
            <h3>2009</h3>
            <p>
              В январе 2009 года РУП «КЗТШ» реорганизовано путем преобразования
              в открытое акционерное общество (ОАО «Кузнечный завод тяжелых
              штамповок»). Предприятие является основным поставщиком заготовок,
              узлов и деталей для валообразующих заводов машиностроительного
              комплекса Республики Беларусь, в том числе: ОАО «МАЗ», РУПП
              «БелАЗ», УП «МЗКТ», ОАО «ММЗ», «МТЗ», ОАО «Амкодор», Белорусская
              ж.д. и др. Потребителями нашей продукции являются также компании
              России, Украины, Германии: ОАО «Тверской экскаватор»; ОАО
              «Брянский завод колесных тягачей»; ОАО «Канашский ААЗ»; ООО
              «Оникс» г. С.-Петербург; ООО «Промснаб» г. Смоленск; ОАО ХК
              «АвтоКраЗ»; ОАО «Мироновский ААЗ»; "Еврофланш ГмбХ" ; "Евген Кляйн
              ГмбХ" и др.
            </p>
            <img src="/images/about-images/2009.jfif" alt="2009" />
          </div>
          <div className="about-content-card">
            <h3>2012</h3>
            <p>
              Весной 2012 года с целью увеличения количества выпускаемых
              лонжеронов была закуплена линия с ЧПУ для изготовления отверстий в
              лонжеронах ф. Soenen №2, которая была введена в эксплуатацию
              осенью того же года. Одновременно с закупками оборудования с ЧПУ в
              цехе силами ОАО "КЗТШ" был разработан и организован участок правки
              лонжеронов гшидроскобами
            </p>
            <img src="/images/about-images/2012.jfif" alt="2012" />
          </div>
          <div className="about-content-card">
            <h3>2014</h3>
            <p>
              В 2014 году в производство завода внедряется новое оборудование:
              <ul>
                <li>
                  - два робототехнических комплекса для электродуговой сварки
                  фирмы "CLOOS"
                </li>
                <li>
                  - гидравличесике гильотинные ножницы с ЧПУ и системой
                  автоматической подачи листа, транспортировкой и складированием
                  деталей
                </li>
                <li>
                  - два токарно-револьверных станка с ЧПУ и контршпинделем для
                  увеличения объемов выпуска деталей трубопроводной аврматуры в
                  МСЦ
                </li>
                <li>
                  - механизированная дробометная установка для повышения
                  качества подготовки поверхностей лонжеронов под катафорезное
                  покрытие
                </li>
              </ul>
            </p>
            <img src="/images/about-images/2014.jfif" alt="2014" />
          </div>
          <div className="about-content-card">
            <h3>2016</h3>
            <p>
              17 июня 2016г. на базе кузнечного цеха и цеха колес в структуру
              завода введен Кузнечный корпус. Начальником кузнечного корпуса
              назначен Горецкий Сергей Викентьевич. Также заводом ведутся работы
              по закупке роботизированного комплекса по восстановлению штампов
              методом профильной наплавки
            </p>
            <img src="/images/about-images/2016.jfif" alt="2016" />
          </div>
          <div className="about-today">
            <h2>Сегодня ОАО "КЗТШ" - ЭТО</h2>
            <p>
              Ведущий производитель горячих штамповок и поковок, поковок
              свободной ковки в машиностроительной отрасли Республики Беларусь
            </p>
            <ul></ul>
            <li> Предприятие входит в состав:</li>
            <li>
              Открытого акционерного общества "Минский автомобильный завод" -
              управляющая компания холдинга "БЕЛАВТОМАЗ"
            </li>
            <li>- Списочная численность - 1220 чел.</li>
            <li>- Занимаемая площадь - 36,117 га.</li>
            <li>- Единиц оборудования -2432 ед.</li>
            <li>
              - В число потребителей продукции завода входят гиганты
              автомобильной и сельскохозяйственной техники: БелАЗ, МАЗ, МЗКТ,
              АМКОДОР, Гомсельмаш, ММЗ, МТЗ, КАМАЗ, Брянский з-д колесных
              тягачей и др.
            </li>
            <li>
              - Удельный вес экспорта товаров в общем объеме товарной продукции
              составляет более 30%
            </li>
            <li>
              - Предприятие располагает: двумя общежитиями на 970 мест; клубом
              на 275 мест; столовой на 220 мест; кафе "Северное", медпунктом;
              физкультурно-оздоровительным комбинатом; спортивными площадками
            </li>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
